import React, { useContext } from "react";
import { Link, useLoaderData, useNavigate } from 'react-router-dom';
import PolicyCard from '../components/PolicyCard';
import Section from '../components/Section';

import about_0 from "../img/about_0.png"
import { AuthContext } from "../providers/AuthProvider";
import { Helmet } from "react-helmet";
import PageTitle from "../components/seo/PageTitle";

export default function AboutPage() {
    const { user, isGuestUser, isFreeUser, isPaidUser } = useContext(AuthContext);

    return (
        <div>
            <PageTitle sub={`About Us`} />
            <div className="d-flex flex-row justify-content-center w-100 px-3" style={{ minHeight: "30vh" }}>
                <div className="flex-grow-1 d-flex flex-row justify-content-center align-items-center tw-max-w-6xl">
                    <div className="tw-max-w-5xl">
                        <h1 className="display-4 fw-bold">About Privacy Police</h1>
                        {/* <p className="fs-5">
                            <u className="text-primary">87%</u> of people accept privacy policies without reading them. We read them so you don't have to.
                        </p> */}
                    </div>
                </div>
            </div>
            <Section className="bg-body-tertiary fs-5">
                <p className="fs-4">
                    TLDR: We use AI to search and evaluate different services' privacy policy pages, against a list of common information types.
                </p>
                <h2>Evaluating Policies</h2>
                <p>
                    When categorizing what information different policies collect, we evaluate the policy against a list of common information
                    types like <i>Health-related information</i>, <i>Location (city-level)</i> or <i>Email</i>. The evaluation is done in three steps.
                </p>
                <ol>
                    <li>Firstly, the privacy policy URL is fetched using a headless browser, to facilitate loading of single-page JavaScript apps running React for example.</li>
                    <li>Secondly, when the page has loaded and rendered, all text on the page is downloaded and saved to our database.</li>

                    <li>
                        The last step is to run the text through our AI, which will categorize exactly what information is collected
                        according to the privacy policy text, along with its purpose and a paragraph for reference. The AI also checks
                        if the information is stated to be shared or sold with third parties.
                    </li>
                </ol>
                <h2>Privacy Assistant</h2>
                <p>
                    When a URL is fetched, the privacy policy text is saved in our database. This enables us to feed the text into our AI assistant to
                    ask further questions without having to refetch the URL every time. The Privacy Assistant is based on the <i>gpt-4o-mini</i> model from openai.
                </p>
            </Section>
            <Section className="bg-body-tertiary">
                <div className="text-center">
                    <h2 className="h1 fw-bold">Try it!</h2>
                    <p className="text-body-secondary fs-5">
                        Start comparing services, or {isGuestUser ? "sign up" : "upgrade"} for more features.
                    </p>
                    <div className="d-flex flex-row justify-content-center gap-2">
                        <Link to={"/policies"} className="btn btn-outline-primary">Compare Services</Link>
                        {isGuestUser ? <Link to={"/signup"} className="btn btn-primary">Sign up</Link> : (isPaidUser ? null : <Link to={"/#pricing"} className="btn btn-primary">Upgrade for $2/mo!</Link>)}
                    </div>
                </div>
            </Section>
        </div>
    )
}
