export const API_URL = process.env.REACT_APP_API_URL ?? "http://localhost:3000"

const get = async (endpoint, extraHeaders) => {
    const res = await (await fetch(API_URL + endpoint, {
        credentials: "include",
        headers: extraHeaders,
        signal: AbortSignal.timeout(6000)
    })).json()

    if (!res.success) {
        console.log(res)
        throw new Error(res.msg ? res.msg : "Unknown error")
    }
    else {
        return res
    }
}

const withBody = async (verb, endpoint, payload) => {
    const res = await (await fetch(API_URL + endpoint, {
        credentials: "include",
        method: verb,
        body: JSON.stringify(payload),
        headers: {
            "Content-Type": "application/json"
        }
    })).json()

    if (!res.success) {
        throw new Error(res.msg ? res.msg : "Unknown error")
    }
    else {
        return res
    }
}

const post = async (endpoint, payload) => {
    return await withBody("post", endpoint, payload)
}

const put = async (endpoint, payload) => {
    return await withBody("put", endpoint, payload)
}

export const search = async (query) => {
    return await post("/policies/search", { query })
}

export const listPolicies = async () => {
    return await get("/policies/list")
}

export const listFeaturedPolicies = async () => {
    return await get("/policies/featured")
}

export const listPopularPolicies = async () => {
    return await get("/policies/popular")
}

export const listRandomPolicies = async () => {
    return await get("/policies/random")
}

export const listCategories = async () => {
    return await get("/policies/categories/list")
}

export const getCategory = async (category) => {
    return await get(`/policies/categories/${category}`)
}

export const getPolicy = async (service) => {
    return await get(`/policies/${service}`)
}

export const policyChat = async (service, question, onData) => {
    const res = await fetch(`${API_URL}/policies/${service}/chat`, {
        credentials: "include",
        method: "POST",
        body: JSON.stringify({ question }),
        headers: {
            "Content-Type": "application/json"
        }
    })

    if (res.status != 200) {
        throw await res.text()
    }

    const reader = res.body.getReader()
    const decoder = new TextDecoder("utf-8")

    while (true) {
        const { done, value } = await reader.read()
        if (done) {
            break
        }

        const chunk = decoder.decode(value, { stream: true })

        onData(chunk)
    }
}

export async function likePolicy(service, userId){
    return await post(`/policies/${service}/like`, {userId})
}
export async function dislikePolicy(service, userId){
    return await post(`/policies/${service}/dislike`, {userId})
}

export async function getUser() {
    return await get("/user")
}

export async function setBookmarks(bookmarks) {
    return await post("/user/bookmarks/set", { bookmarks })
}

export async function addNotification(service) {
    return await post("/user/notifications/add", { service })
}

export async function removeNotification(service) {
    return await post("/user/notifications/remove", { service })
}

export async function getScans() {
    return await get("/user/scans")
}

export async function login(email, password) {
    return await post("/auth/login", { email, password })
}

export async function logout() {
    return await post("/auth/logout", {})
}

export async function register(email, password) {
    return await post("/auth/register", { email, password })
}

export async function requestPasswordReset(email) {
    return await post("/auth/passwordreset/request", { email })
}

export async function doPasswordReset(email, token, newPass) {
    return await post("/auth/passwordreset/do", { email, token, newPass })
}

export async function requestVerification() {
    return await post("/auth/verification/request", {})
}

export async function requestPendingScanLink(email, url) {
    return await post("/auth/pendingscanlink/request", { email, url })
}

export async function doVerification(email, token) {
    return await post("/auth/verification/do", { email, token })
}

export async function createCheckoutSession(plan, period) {
    window.location.href = `${API_URL}/create-checkout-session?plan=${plan}&period=${period}`
}

// ChatGPT converted code
export async function scanPolicy(scanToken, policy_url, onProgress) {
    return new Promise((resolve, reject) => {
        // Initialize the WebSocket connection
        const apiUrlWithoutProtocol = API_URL.replace(/^https?:/, '')
        const wsProtocol = window.location.protocol == "https:" ? "wss:" : "ws:"
        const ws = new WebSocket(`${wsProtocol}//${apiUrlWithoutProtocol}/scan_policy`)

        ws.onopen = () => {
            // Send the initial message with the policy URL
            ws.send(JSON.stringify({ type: "body", policy_url, scanToken }))
        }

        ws.onmessage = (event) => {
            // Handle incoming WebSocket messages (progress updates)
            console.log(event.data)
            try {
                const message = event.data
                const jsonObject = JSON.parse(message)

                // Handle progress updates
                if (jsonObject.type == "progress") {
                    onProgress(jsonObject.progressObj)
                }

                // Handle completion (final result)
                if (jsonObject.success) {
                    resolve(jsonObject)  // Final response after progress is done
                    ws.close()
                }
                else if (jsonObject.error) {
                    reject(jsonObject.msg)
                }
            } catch (error) {
                reject(error?.message || error.toString())
                ws.close()
            }
        }

        ws.onerror = (error) => {
            console.error("WebSocket error:", error)
            reject(error?.message || error.toString())
        }

        ws.onclose = () => {
            console.log("WebSocket connection closed")
        }
    })
}
