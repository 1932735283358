import { Link, useLoaderData, useLocation, useNavigate } from "react-router-dom";
import { dislikePolicy, getCategory, getPolicy, likePolicy, policyChat } from '../Api';
import { capitalizeFirstLetter, gradeLetterFromScore, getBehaviourInList, textColorFromScore } from '../utils';
import InformationCard from '../components/InformationCard';
import { useContext, useEffect, useMemo, useRef, useState } from "react";
import { AuthContext } from "../providers/AuthProvider";
import { ApplicationContext } from "../providers/ApplicationProvider";
import BehaviourCard from "../components/BehaviourCard";
import { Helmet } from "react-helmet";
import PageTitle from "../components/seo/PageTitle";

export async function loader({ params }) {
  const { policy } = await getPolicy(params.policy);
  const { category } = await getCategory(policy.category)
  return { policy, category };
}

export default function PolicyPage() {
  const { policy, category } = useLoaderData()
  const { service, service_url, service_favicon, policy_url, evaluation, lastUpdated, score, user_content, is_liked, is_disliked, likes, dislikes } = policy

  const { user, isPaidUser, isGuestUser, isFreeUser } = useContext(AuthContext)
  const { setShowSubscribeModal, setShowSignUpModal, sharePolicy } = useContext(ApplicationContext)

  const sortedInformationCollected = useMemo(() => evaluation.information_collected.sort((a, b) => b.score - a.score), [evaluation])
  const filteredInformationCollected = useMemo(() => sortedInformationCollected.filter(entry => entry.type !== "other"), [sortedInformationCollected])
  const otherCount = useMemo(() => evaluation.information_collected.reduce((count, entry) => {
    if (entry.type === "other") {
      return count + 1
    }
    return count
  }, 0))

  const { state } = useLocation()
  console.log(policy)

  const lastUpdatedString = useMemo(() => new Date(lastUpdated).toLocaleDateString(undefined, { day: 'numeric', month: 'long', year: 'numeric' }), [policy])

  const questionInputRef = useRef(null)
  const [chatQuestion, setChatQuestion] = useState(null)
  const [chatAnswer, setChatAnswer] = useState(null)
  const [chatAnswerDone, setChatAnswerDone] = useState(true)
  const [isLiked, setIsLiked] = useState(0)

  useEffect(() => {
    if (is_liked) {
      setIsLiked(1)
    } else if (is_disliked) {
      setIsLiked(-1)
    } else {
      setIsLiked(0)
    }
  }, [])

  const SmallInformationLink = ({ information, to }) => {
    return (
      <li><Link className="link-underline link-underline-opacity-0 link-underline-opacity-100-hover text-body"
        to={`#${to}`}
        replace={true}>
        {information.title}
      </Link></li>
    )
  }

  const askQuestion = (question) => {
    setChatQuestion(question)
    setChatAnswerDone(false)
    let _chatAnswer = ""
    setChatAnswer(_chatAnswer)
    questionInputRef.current.value = ""
    policyChat(service, question, data => {
      _chatAnswer = _chatAnswer + data
      setChatAnswer(_chatAnswer)
    }).then(() => {
      setChatAnswerDone(true)
      setTimeout(() => questionInputRef.current.focus(), 100)
    })
  }

  const onQuestionFormSubmit = e => {
    e.preventDefault()
    if (!isPaidUser) {
      setShowSubscribeModal(true, "Subscribe to Ask Questions")
    }
    else {
      const formData = new FormData(e.target)
      const question = formData.get("question")

      askQuestion(question)
    }
  }

  const onInputClick = () => {
    if (!isPaidUser) {
      setShowSubscribeModal(true, "Subscribe to Ask Questions")
    }
  }

  const onInputChange = e => {
    if (!isPaidUser) {
      e.preventDefault()
      e.target.value = ""
      setShowSubscribeModal(true, "Subscribe to Ask Questions")
    }
  }

  const onShareBtnClicked = () => {
    sharePolicy(policy)
  }

  const onLikeBtnClicked = () => {
    likePolicy(service, user.id)

    if (isLiked == 1) {
      setIsLiked(0)
    } else {
      setIsLiked(1)
    }
  }

  const onDislikeBtnClicked = () => {
    dislikePolicy(service, user.id)

    if (isLiked == -1) {
      setIsLiked(0)
    } else {
      setIsLiked(-1)
    }
  }

  const spinner = (
    <div className="spinner-grow spinner-grow-sm" role="status">
      <span className="visually-hidden">Loading...</span>
    </div>
  )

  const scanCustomPolicyLinkClicked = e => {
    if (isGuestUser) {
      e.preventDefault()
      setShowSubscribeModal(true, "Scan Custom Policies")
    }
    else if (isFreeUser) {
      e.preventDefault()
      setShowSubscribeModal(true, "Subscribe to Scan")
    }
  }

  const sellsUserData = getBehaviourInList(evaluation.behaviours, "shares_user_data_for_money")
  const sharesUserData = getBehaviourInList(evaluation.behaviours, "shares_user_data")

  const shownBehaviour = sellsUserData || sharesUserData || null

  const randomScore = Math.random() * 3

  const AlternativesBox = ({ className }) => {
    if (category.name == "other") return <></>
    return (
      <div className={`align-items-stretch gap-2 ${className}`}>
        <div className="d-flex flex-row flex-grow-1">
          <div className="bg-body border rounded-3 shadow-sm p-3 d-flex flex-column gap-1 w-100">
            <h4 className="fw-bold">{gradeLetterFromScore(score) == "A" ? "Alternatives" : "Best Alternatives"}</h4>
            {category.policies_truncated.filter((elem) => !(elem.service === policy.service)).map((elem) => {
              return (
                <Link to={"/policy/" + elem.service} className="text-decoration-none d-flex justify-content-between">
                  <div className="d-flex align-items-center">
                    {
                      elem.service_favicon
                        ?
                        <img className="rounded-circle tw-w-5 tw-h-5 me-1 d-flex justify-content-center align-items-center" src={elem.service_favicon}></img>
                        :
                        <span className="rounded-circle tw-w-5 tw-h-5 bg-primary  me-1 d-flex justify-content-center align-items-center "><span className="text-white tw-text-xs">{elem.service[0].toUpperCase()}</span></span>
                    }
                    <span className="text-secondary">{elem.service}</span>
                  </div>
                  <span className={"px-2 py-auto bg-body-tertiary rounded-2 " + textColorFromScore(elem.score)} >{gradeLetterFromScore(elem.score)}</span></Link>
              )
            })}
            <span className="text-secondary mt-2 flex-wrap">Missing a policy? <Link onClick={scanCustomPolicyLinkClicked} className="text-secondary" to="/scan-policy">Scan one here</Link></span>
          </div>
        </div>
        {/* <div className="d-flex flex-row-reverse">
          <div className="bg-body border rounded-3 shadow-sm p-3">
            <h4>Community score <span className={"px-2 py-auto bg-body-tertiary rounded-2 " + textColorFromScore(randomScore)} >{gradeLetterFromScore(randomScore)}</span> </h4>
            <div className="d-flex flex-row justify-content-between flex-wrap gap-2">
              <span className="text-secondary">Like this service? </span>
              <div className="d-flex gap-2">
                <button onClick={onLikeBtnClicked} className={"btn btn-sm btn-light border rounded-3 " + (isLiked === 1 ? "text-primary bg-body-secondary" : "")} >{likes} <i className="bi bi-hand-thumbs-up"></i></button>
                <button onClick={onDislikeBtnClicked} className={"btn btn-sm btn-light border rounded-3 " + (isLiked === -1 ? "text-danger bg-body-secondary" : "")} >{dislikes} <i className="bi bi-hand-thumbs-down"></i></button>
              </div>
            </div>
          </div>
        </div> */}
      </div>
    )
  }

  return (
    <div className="bg-body-tertiary tw-min-h-[80vh]">
      <PageTitle sub={`Information ${service} Collects About You, Summarized ${lastUpdatedString} with Best Alternatives`} />
      <div className="px-3 pt-4 pb-5 w-full">
        {user_content && (
          <div className="m-auto tw-max-w-6xl w-100 gap-3 mb-4">
            <div className="fs-6 px-2 py-1 d-flex flex-row justify-content-center text-secondary bg-secondary-subtle border-secondary rounded-2 border">
              <span><i className="bi bi-exclamation-triangle me-1"></i>This policy is not verified by a human. AI can make mistakes.</span>
            </div>
          </div>
        )}
        <div className="m-auto tw-max-w-6xl d-flex flex-row justify-content-between gap-3">
          <div className="flex-grow-1">
            <div className="d-flex flex-row align-items-center gap-2 tw-max-w-xl">
              <div className="d-flex flex-row align-items-center mb-2">
                {service_favicon && <img className="d-inline-block flex-shrink-1 me-2" width={40} src={service_favicon}></img>}
                <div className="d-flex flex-row align-items-stretch">
                  <h1 className="fw-bold display-5 mb-0"><nobr><a className="text-body" style={{ textDecoration: "none" }} href={service_url}>{service}</a></nobr></h1>
                  <div>
                    <a target="_blank" className="ms-2 fs-6" href={policy_url}><i className="bi bi-box-arrow-up-right"></i></a>
                  </div>
                </div>

              </div>
              <div className="d-flex flex-row w-100 justify-content-end ">
                <div className={"bg-body-secondary d-flex justify-content-center align-items-center tw-w-8 tw-h-8 rounded-2 h3 mb-0 " + textColorFromScore(score)}>
                  {gradeLetterFromScore(score)}
                </div>
              </div>
            </div>
            <p className="fs-5 text-body-secondary">A list of information that may be collected by {service}.</p>
            <hr className="px-2 tw-max-w-xl"></hr>
            <AlternativesBox className={"d-flex d-md-none flex-row tw-max-w-xl"} />
            <div className="d-flex flex-column gap-3 mt-4">
              <form className="d-flex flex-column" onSubmit={onQuestionFormSubmit}>
                <input
                  ref={questionInputRef}
                  value={isGuestUser ? "" : undefined}
                  onClick={onInputClick}
                  onChange={onInputChange}
                  autoFocus={!!state?.chat}
                  // disabled={!isPaidUser || !chatAnswerDone}
                  placeholder="Ask a question"
                  name="question"
                  className="tw-max-w-xl rounded-pill border px-3 py-2 bg-body-secondary text-body-secondary" />
              </form>
              {chatQuestion !== null && (
                <div className="tw-max-w-xl">
                  <p className="text-body-secondary mb-0"><i className="bi bi-person me-1"></i>{chatQuestion}</p>
                  <p style={{ whiteSpace: "pre-line" }}>{chatAnswer}{!chatAnswerDone && spinner}</p>
                </div>
              )}
              {shownBehaviour && <BehaviourCard behaviour={shownBehaviour} />}
              {sortedInformationCollected.map((entry, i) => <InformationCard key={entry.type + i} id={i} information={entry} />)}
              <span className="text-secondary d-block d-md-none">
                <i className="bi bi-clock me-1"></i>{lastUpdatedString}
              </span>
            </div>
          </div>
          <div className="d-flex flex-column align-items-end d-none d-md-block">
            <AlternativesBox className={"d-flex flex-column"} />
            <div className="py-2 tw-max-h-screen sticky-top overflow-y-hidden tw-min-w-64" style={{ zIndex: "0" }}>
              <div className="bg-body border rounded-3 shadow-sm p-3 pb-2 mb-2">
                <div className="d-flex flex-row justify-content-between align-items-stretch mb-2">
                  <div className="d-flex flex-row align-items-center ">
                    {service_favicon && <img className="d-inline-block flex-shrink-1 me-1" width={20} src={service_favicon}></img>}
                    <h4 className="mb-0 fw-bold">{service}</h4>
                  </div>
                  <button onClick={onShareBtnClicked} className="btn btn-sm border rounded-3" ><i className="bi bi-share-fill"></i></button>
                </div>
                <ul className="list-unstyled mb-0 me-5">
                  {filteredInformationCollected.map((entry, i) => <SmallInformationLink key={entry.type + i} to={i} information={entry} />)}
                  {otherCount > 0 && (
                    <li><Link className="link-underline link-underline-opacity-0 link-underline-opacity-100-hover text-body" to={`#${filteredInformationCollected.length}`} replace={true}>
                      Other ({otherCount})
                    </Link></li>
                  )}
                </ul>
                <small className="text-secondary">
                  <i className="bi bi-clock me-1"></i>{lastUpdatedString}
                </small>
                {/* <Link className="text-secondary link-underline link-underline-opacity-0 link-underline-opacity-100-hover mb-0">
                  <small>Add a summary to your policy<i className="bi bi-arrow-right-short"></i></small>
                </Link> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
