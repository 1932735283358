import React from "react";
import Section from "../components/Section";
import SmallHeading from "../components/SmallHeading";
import { Accordion } from "react-bootstrap";
import PolicyCard from "../components/PolicyCard";
import PolicyCardList from "../components/PolicyCardList";

export default function FeaturePolicyPage() {

    const mockPolicy = {
        service: "Your Company",
        service_url: "https://example.org",
        evaluation: {
            information_collected: [
                { type: "example", title: "Example Information", score: 2 },
                { type: "example", title: "Test Information", score: 1 },
                { type: "example", title: "Mock Information", score: 0 },
            ],
            behaviours: []
        },
        category: "example category",
        score: 0,
        advertisement: true,
        advertisement_special: "33% OFF"
    }

    return (
        <div>
            <SmallHeading title={"Feature your service!"} helmet={true}>
            </SmallHeading>
            <Section className="bg-body-tertiary">
                <div>
                    <div className="d-flex flex-row gap-3">
                        <div>
                            <h2 className="fw-bold h1">Reach thousands of loyal users.</h2>
                            <p className="fs-5 text-body-secondary">
                                Do you want to feature your (preferably) privacy-friendly service on our site?
                                Contact us at <a href="mailto:contact@privacypolice.ai">contact@privacypolice.ai</a> to see if we are a good match.
                                From there we may discuss eventual pricing and other terms.
                            </p>
                            <div className="d-flex flex-row justify-content-center d-sm-none">
                                <PolicyCardList policies={[mockPolicy]} showCategory={true} />
                            </div>
                            <p className="fs-5 text-body-secondary">
                                Your service will be featured on our landing page, along with your privacy score and list of information your service collects.
                            </p>
                        </div>
                        <div className="d-none d-sm-inline-block">
                            <PolicyCardList policies={[mockPolicy]} showCategory={true} />
                        </div>
                    </div>
                    {/* <div>
                        <h2 className="h2 fw-bold mb-4">FAQ</h2>
                        <div className="tw-max-w-2xl">
                            <Accordion className="fs-5" defaultActiveKey="0">
                                <Accordion.Item eventKey="0">
                                    <Accordion.Header><span className="fs-5"></span></Accordion.Header>
                                    <Accordion.Body>
                                        sadads
                                    </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="1">
                                    <Accordion.Header><span className="fs-5">Test</span></Accordion.Header>
                                    <Accordion.Body>
                                        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                                        eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
                                        minim veniam, quis nostrud exercitation ullamco laboris nisi ut
                                        aliquip ex ea commodo consequat. Duis aute irure dolor in
                                        reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
                                        pariatur. Excepteur sint occaecat cupidatat non proident, sunt in
                                        culpa qui officia deserunt mollit anim id est laborum.
                                    </Accordion.Body>
                                </Accordion.Item>
                            </Accordion>
                        </div>
                    </div> */}
                </div>
            </Section>
        </div>
    )
}
